import React, { useCallback, useEffect } from 'react'

import { hooks } from '@front/volcanion'
import { Loader } from '@front/squirtle'

import Callbacks from './callbacks'

const withContainer = Component => props => {
  const [gsm, application_id] = hooks.useFieldValues(['user.gsm', 'user.application'])

  const [application] = hooks.useModel('application', [application_id], { single: true })
  const [ordersCount, searchOrder] = hooks.useModelSearchCount('order', 'get', { populate: [] })
  const [jobs, searchJob] = hooks.useModelSearch('job', 'get', { populate: ['orders'] })

  const [user_id] = hooks.useFormState('user_id')
  const [job_id] = hooks.useFormState('job_id')

  const handleClickClientDetail = useCallback(Callbacks.handleClickClientDetailHandler(user_id), [user_id])
  const handleClickClientHistory = useCallback(Callbacks.handleClickClientHistoryHandler(gsm), [gsm])
  const handleClickOrderJob = useCallback(Callbacks.handleClickOrderJobHandler(job_id), [job_id])
  const handleClickCollectoMap = useCallback(Callbacks.handleClickCollectoMapHandler(), [])

  useEffect(() => {
    !!user_id && searchOrder({ do: user_id, confirmed: true, status: { '!': ['canceled', 'complete'] } })
  }, [user_id])

  useEffect(() => {
    !!job_id && searchJob({ job_id })
  }, [job_id])

  const mergedProps = {
    ordersCount,
    jobOrdersCount: (_.head(jobs)?.orders || []).length,
    handleClickClientDetail,
    handleClickClientHistory,
    handleClickOrderJob,
    handleClickCollectoMap,
    isCollecto: _.get(application, 'application_id') === 'ryypcU2pQ',
    hasJob: !!job_id,
    hasUser: !!user_id,
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer
