import React from 'react'
import { I18n } from '@front/volcanion/'

import { Box, Divider, Grid, List, ListItem, Typography, Link } from '@mui/material'

const CancelPopup = ({ grouped_orders, order, ...props }) =>
  <Grid container spacing={1} direction='row' justifyContent='center' alignItems='center'>
    <Grid item xs={10}> <Divider /> </Grid>
    <Grid item xs={12}>
      <Typography>{I18n.t('order.cancel_grouped')}</Typography>
    </Grid>
    <Grid item xs={7}>
      <List>
        {_.map(grouped_orders, order =>
          <ListItem button onClick={() => window.open(`/order/${_.get(order, 'order_id')}`, '_blank')}>
            <Box display='flex' alignItems='center' justifyContent='center'>
              <Typography color='primary'
                sx={{
                  fontWeight: 600,
                  fontSize: 'subtitle1.fontSize',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Link sx={{ color: 'inherit' }}
                  target='_blank'
                  onClick={(e) => {
                    window.open(`/order/${_.get(order, 'order_id')}`, '_blank')
                    e.stopPropagation()
                  }}
                >
                  {_.get(order, 'order_client_id')}
                </Link>
              </Typography>
              <Typography sx={{ ml: 1, mr: 1 }}> {I18n.t('prefix.separator')} </Typography>
              <Typography > {I18n.t(`order.status.${_.get(order, 'status')}`)} </Typography>
            </Box>
          </ListItem>
        )}
      </List>
    </Grid>
    <Grid item xs={10}> <Divider /> </Grid>
    <Grid item xs={11}>
      <Typography> {I18n.t('order.cancel_confirm', { order_client_id: _.get(order, 'order_client_id') })}</Typography>
    </Grid>
  </Grid >

export default CancelPopup
