import React from 'react'
import { I18n } from '@front/volcanion'
import { useTheme } from '@mui/styles'


import { Typography, Box, IconButton, Grid, StepLabel, StepContent } from '@mui/material'
import { SingleOptionSelectorField, TextField, TextComponent, AddressAutocompleteField } from '@front/squirtle'

import SaveIcon from '@mui/icons-material/Save'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import FavoriteIcon from '@mui/icons-material/Favorite'

const Row1 = ({
  prefix,
  user_id,
  load_type,
  favorite_address,
  handleSetFavoriteAddress,
  onAddressResolve,
  disabledIconButton,
  isB2B,
  commercial_formula,
  schedule_type,
  ...props
}) =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs>
      <Box sx={{ display: 'flex' }}>
        <IconButton onClick={handleSetFavoriteAddress} disabled={disabledIconButton}>
          <Box color={!!favorite_address || !disabledIconButton ? 'pink' : 'text.label'}>
            {favorite_address ? <FavoriteIcon /> : <FavoriteBorderIcon />}
          </Box>
        </IconButton>
        <AddressAutocompleteField
          name={prefix}
          search_id={prefix === 'source' ? 'address_source' : 'address_destination'}
          label={I18n.t(`address.${prefix}`)}
          noOptionsText={I18n.t('no_result')}
          owner={user_id}
          initialConfig={{
            includePOI: load_type !== 'package',
            load_type,
            requestOptions: {
              recentLimit: 4,
              favoriteLimit: 4,
              commercial_formula,
              direction_type: prefix,
              schedule_type
            },
            watchers: [load_type, user_id, isB2B, commercial_formula, schedule_type, prefix]
          }}
          required={prefix === 'source' || load_type === 'package'
          }
          resolveAddress
          onAddressResolve={onAddressResolve}
          getOptionIcon={(option) => {
            if (_.get(option, 'favorite')) return 'favorite'
            if (_.get(option, 'type') === 'home') return 'home'
            else if (_.get(option, 'type') === 'work') return 'work'
            else if (_.get(option, 'type') === 'airport') return 'flighttakeoff'
            else if (_.get(option, 'type') === 'festival') return 'festival'
            else if (_.get(option, 'type') === 'train') return 'train'
            else if (_.get(option, 'address_id')) return 'update'
          }}
        />
      </Box>
    </Grid>
  </Grid>

const Row2 = ({ companyuser_id, vehicle_count, load_type, isB2B, guest_booking, guest, handleGuestChange, prefix, prefix_field }) => {
  const theme = useTheme()
  return (
    <Grid container item xs={12} spacing={1}>
      <Grid item xs hidden={prefix !== 'source' && load_type !== 'package'}>
        <TextField
          name={`loads.${prefix_field}_contact`}
          label={I18n.t('transport.passenger')}
          disabled={(!!isB2B && load_type === 'user') && ((!guest && !!companyuser_id) || vehicle_count > 1)}
          required={vehicle_count === 1 && prefix !== 'destination' && load_type !== 'package'}
          alwaysInclude
        />
      </Grid>
      <Grid item xs hidden={prefix !== 'source' && load_type !== 'package'}>
        <TextField
          name={`loads.${prefix_field}_phone`}
          label={I18n.t('phone.label', { count: 1 })}
          disabled={(!!isB2B && load_type === 'user') && ((!guest && !!companyuser_id) || vehicle_count > 1)}
          alwaysInclude
          required={!guest && prefix !== 'destination'}
        />
      </Grid>
      <Grid item xs hidden={!isB2B || prefix !== 'source'}>
        <SingleOptionSelectorField
          name={'guest'}
          optionLabel={I18n.t('order.guest')}
          disabled={!guest_booking}
          afterChange={handleGuestChange}
          getOptionLabelProps={() => ({
            disableTypography: false,
            componentsProps: { typography: { fontSize: 12, color: `${theme.palette.text.label} !important` } }
          })}
        />
      </Grid>
      <Grid item xs hidden={!isB2B || prefix !== 'source'}>
        <SingleOptionSelectorField
          name={'fill_by_driver'}
          optionLabel={I18n.t('fill_by_driver')}
          disabled
          getOptionLabelProps={() => ({
            disableTypography: false,
            componentsProps: { typography: { fontSize: 12, color: `${theme.palette.text.label} !important` } }
          })}
        />
      </Grid>
    </Grid>
  )
}

const Row3 = ({ isReadOnly, reservationinfo, additional_info_required, handleIsReservationinfoChange }) =>
  <Grid container item spacing={1}>
    {!isReadOnly && <Grid item xs>
      <SingleOptionSelectorField
        name={'is_reservationinfo'}
        label={reservationinfo?.arrival_translated}
        afterChange={handleIsReservationinfoChange}
      />
    </Grid>
    }
    <Grid item xs >
      <TextField
        name={'reservationinfo.name'}
        label={_.get(reservationinfo, 'name_translated')}
        required={additional_info_required}
      />
    </Grid>
    <Grid item xs >
      <TextField
        name={'reservationinfo.origin'}
        label={_.get(reservationinfo, 'origin_translated')}
        required={additional_info_required}
      />
    </Grid>
  </Grid>

const Row4 = ({ handleReplaceComment, disabledIconButton, save_comment, prefix_field }) =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs>
      <Box sx={{ display: 'flex' }}>
        <IconButton onClick={handleReplaceComment} disabled={disabledIconButton}>
          <Box color={!!save_comment || !disabledIconButton ? 'green' : 'text.label'}>
            {!!save_comment ? <SaveIcon /> : <SaveOutlinedIcon />}
          </Box>
        </IconButton>
        <TextField
          name={`info.${prefix_field}_comment`}
          label={I18n.t('comment.label', { count: 1 })}
        />
      </Box>
    </Grid>
  </Grid>

const AddressSection = props => {
  const { prefix, display_additional_info } = props
  return <>
    <StepLabel>
      <Typography variant='h5'>{I18n.t(`address.${prefix}`)}</Typography>
    </StepLabel>
    <StepContent>
      <Grid container spacing={1} rowSpacing={1.5}>
        <Row1 {...props} />
        <Row2 {...props} />
        {!!display_additional_info && prefix === 'source' && <Row3 {...props} />}
        <Row4 {...props} />
      </Grid>
    </StepContent>
  </>
}

export default React.memo(AddressSection)
