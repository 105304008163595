import React, { useCallback } from 'react'
import { I18n } from '@front/volcanion'

import { TextField, MenuSelectorField, ModelMenuSelectorField } from '@front/squirtle'
import { Grid } from '@mui/material'

const Row1 = ({
  searchGSM,
  searchClientNumber,
  normalizePhone,
  isB2B,
  disabled
}) =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs hidden={!!isB2B}>
      <TextField
        name='user.gsm'
        label={I18n.t('phone.label', { count: 1 })}
        afterChange={searchGSM}
        normalize={normalizePhone}
        debounceTime={250}
        autoFocus
        required={!isB2B}
        disabled={disabled}
      />
    </Grid>
    <Grid item xs>
      <TextField
        name='user.client_number'
        label={I18n.t('user.number')}
        debounceTime={250}
        afterUserChange={searchClientNumber}
        disabled={disabled}
      />
    </Grid>
    <Grid item xs>
      <TextField
        name='user.first_name'
        label={I18n.t('name.first_name', { count: 1 })}
        disabled={disabled}
      />
    </Grid>
    <Grid item xs>
      <TextField
        name='user.last_name'
        label={I18n.t('name.label', { count: 1 })}
        disabled={disabled}
      />
    </Grid>
  </Grid >


const Row2 = (props) => {
  const {
    TAXI_ENABLED,
    PACKAGE_ENABLED,
    onApplicationChange,
    onLoadTypeChange,
    application_ids,
    load_type,
    user,
    commercial_formula,
    application,
    service,
    handleChangeRidemode,
    disabled
  } = props || {}

  return (
    <Grid container item xs={12} spacing={1}>
      <Grid item xs>
        <TextField
          name='user.name'
          label={I18n.t('designation')}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs hidden>
        <ModelMenuSelectorField
          name='user.application'
          label={I18n.t('application.label', { count: 1 })}
          model_name='application'
          config={{
            ids: application_ids,
            sortBy: ['display_index'],
            sortDirection: ['asc']
          }}
          labelKeys={['name']}
          afterChange={onApplicationChange}
          selectFirst
          required
          disabled={disabled}
        />
      </Grid>
      <Grid item xs>
        <MenuSelectorField
          name='load_type'
          label={I18n.t('load.label', { count: 1 })}
          options={_.compact([
            !!TAXI_ENABLED ? { label: I18n.t(`load.user.label`, { count: 1 }), value: 'user' } : null,
            !!PACKAGE_ENABLED ? { label: I18n.t(`load.package.label`, { count: 1 }), value: 'package' } : null
          ])}
          selectFirst
          afterChange={onLoadTypeChange}
          required
          disabled
        />
      </Grid>
      <Grid item xs>
        <ModelMenuSelectorField
          name={'ridemode'}
          label={I18n.t('ride.label', { count: 1 })}
          model_name={'ridemode'}
          afterUserChange={handleChangeRidemode}
          labelKeys={['name_translated']}
          searchMode={'search'}
          model_func='getAuthorized'
          required
          config={{
            sort: ['display_index ASC'],
            forced_filter: {
              load_type,
              user,
              commercial_formula,
              application,
              service
            },
            watchers: [
              load_type,
              user,
              commercial_formula,
              application,
              service
            ],
            validate_filter: useCallback(() => !!load_type && !!application, [load_type, application])
          }}
          disabled={disabled}
        />
      </Grid>
    </Grid>)
}

const Row3 = (props) => {
  const { commercial_formula } = props || {}

  return (
    <Grid container item xs={12} spacing={1}>
      <Grid item xs={3}>
        <ModelMenuSelectorField
          name={'user.commercial_formula'}
          label={I18n.t('commercial_formula.label', { count: 1 })}
          model_name={'commercialformula'}
          labelKeys={['name']}
          config={{ ids: [commercial_formula] }}
          disabled
          loadOnMount
          allowNone={false}
          selectFirst
        />
      </Grid>
      <Grid item xs>
        <TextField
          name='user.address'
          label={I18n.t('address.label', { count: 1 })}
          disabled
        />
      </Grid>
    </Grid>
  )
}

const Row4 = props =>
  <Grid item xs={12}>
    <TextField
      name='user.comment_to_call_taker'
      label={I18n.t('comment.label', { count: 1 })}
      disabled
    />
  </Grid>

const B2CSection = props =>
  <Grid container spacing={1} rowSpacing={1.5} hidden>
    <Row1 {...props} />
    <Row2 {...props} />
    <Row3 {...props} />
    <Row4 {...props} />
  </Grid>

export default React.memo(B2CSection)
