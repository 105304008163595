import React, { useCallback, useMemo } from 'react'
import { hooks } from '@front/volcanion'
import _ from 'lodash'
const withContainer = Component => props => {
  const mergedProps = {
  }
  return (
    <Component {...mergedProps} {...props} />
  )
}
export default withContainer
