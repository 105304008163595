import { hooks } from '@front/volcanion'

class Callbacks {
  static recordToFormHandler() {
    return function recordToForm(record) {
      return {
        description_trkey: _.get(record, 'description_trkey'),
        name_trkey: _.get(record, 'name_trkey'),
        delay: _.get(record, 'delay'),
        categoryoption: _.get(record, 'categoryoption'),
        priority: _.get(record, 'priority'),
        search_range: _.get(record, 'search_range'),
        customer_display: _.get(record, 'customer_display'),
        driver_display: _.get(record, 'driver_display'),
        price: _.get(record, 'price'),
        display_index: _.get(record, 'display_index')
      }
    }
  }

  static formToRecordHandler() {
    return function formToRecord(values, extra, meta) {
      return {
        description_trkey: _.merge(_.get(values, 'description_trkey'), { theme: 'option' }),
        name_trkey: _.merge(_.get(values, 'name_trkey'), { theme: 'option' }),
        delay: _.get(values, 'delay'),
        priority: _.get(values, 'priority'),
        search_range: _.get(values, 'search_range'),
        categoryoption: _.get(values, 'categoryoption'),
        customer_display: _.get(values, 'customer_display'),
        driver_display: _.get(values, 'driver_display'),
        price: _.get(values, 'price'),
        display_index: _.get(values, 'display_index')
      }
    }
  }

  static recordToStateHandler(locale) {
    return function recordToState(record) {
      return {
        title: _.get(record, `name_trkey.${locale}`)
      }
    }
  }

  static onSubmitSuccessHandler() {
    return async function onSubmitSuccess(result, values, extra, meta) {
      console.log("🚀 ~ file: callbacks.js ~ line 27 ~ Callbacks ~ onSubmitSuccess ~ result, values, extra, meta", result, values, extra, meta)

    }
  }
  static onSubmitFailedHandler() {
    return async function onSubmitFailed(err, values, extra, meta) {
      console.log("🚀 ~ file: callbacks.js ~ line 27 ~ Callbacks ~ onSubmitFailed ~ err, values, extra, meta", err, values, extra, meta)

    }
  }

}

export default Callbacks
