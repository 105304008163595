import React, { useCallback } from 'react'
import { hooks, I18n } from '@front/volcanion/'

import { Typography, Grid, Box, StepLabel, StepContent } from '@mui/material'

import { SearchFrame, SearchForm, SearchTable, TableHeader, TableRow, TableRowActions, TableActions, FormContent } from '@front/squirtle'

import Form from './Form'
import Header from './Header'
import Row from './Row'
import ButtonGlobalAction from './ButtonGlobal'
import ButtonRowAction from './ButtonRow'
import Callbacks from './callbacks'

const EquipmentSection = ({ isManagingRecord, ...props }) => {
  const { license_id } = hooks.useParams()
  const filterToForm = useCallback(Callbacks.filterToFormHandler(), [])
  const formToFilter = useCallback(Callbacks.formToFilterHandler(license_id), [license_id])
  const recordToForm = useCallback(Callbacks.recordToFormHandler(), [])
  const formToRecord = useCallback(Callbacks.formToRecordHandler(license_id), [license_id])
  const getEmptyForm = useCallback(Callbacks.getEmptyFormHandler(), [])

  return <>
    <StepLabel>
      <Typography variant='h5'>{I18n.t('equipment.label', { count: 1 })}</Typography>
    </StepLabel>
    {!!isManagingRecord &&
      <StepContent >
        <Box sx={{ p: 2, boxShadow: 3, borderRadius: 1 }}>
          <SearchFrame
            model_name='licenseequipment'
            allowCreate={false}
            createPlacement='bottom'
          >
            <SearchForm
              filterToForm={filterToForm}
              formToFilter={formToFilter}
              submitOnMount
              disableButton
            >
              <FormContent><Form /></FormContent>
            </SearchForm>
            <SearchTable
              formProps={{
                recordToForm,
                formToRecord,
                submitChangesOnly: false,
              }}
              tableProps={{
                enableToolbar: false,
                enablePagination: false,
                enableRowActions: true,
                enableRowSelection: false,
                enableGlobalActions: true,
                enableGlobalActionsPlacement: 'bottom',
                disableCancelRowLabel: true,
                disableSaveRowLabel: true
              }}
            >
              <TableHeader><Header /></TableHeader>
              <TableRow><Row /></TableRow>
              <TableRowActions><ButtonRowAction icon='delete' /></TableRowActions>
              <TableActions><ButtonGlobalAction getEmptyForm={getEmptyForm} /></TableActions>
            </SearchTable>
          </SearchFrame >
        </Box>
      </StepContent>}
  </>
}

export default React.memo(EquipmentSection)
