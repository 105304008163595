
import TimeUtils from '@front/volcanion/utils/time'
import BillingUtils from '@front/volcanion/utils/billing'
import FormatUtils from '@front/squirtle/utils/format'

import moment from 'moment'
class Callbacks {
  static refreshETAHandler(getDetails, setEta, setEstimatedDelay) {
    return async function refreshETA(driver_opts, vehicle_opts, source_details, user_id, commercial_package, load_count, has_luggage) {
      const { eta } = await getDetails({
        filter: {
          ..._.pick(source_details, ['place_id', 'address_id', 'poi_client_id', 'coordinates', 'poi_id'])
        },
        owner: user_id,
        options: {
          request: ['eta'],
          commercial_package,
          driver_opts,
          vehicle_opts,
          load_count,
          has_luggage
        }
      })

      setEstimatedDelay(TimeUtils.secondsToTime(moment(eta).diff(moment(), 'seconds'), 'HH[h]mm[min]'))
      setEta(eta)
    }
  }
  static getCustomPropsHandler(selectedEstimation, order, requestedAtFieldValue, schedule_type, eta, margin) {
    return function getCustomProps() {
      if (!!selectedEstimation) {
        return {
          price: BillingUtils.getEstimationDisplay(selectedEstimation?.details?.pricing, true, 1),
          distance: FormatUtils.distance(_.get(selectedEstimation, 'details.distances.total', 0)),
          pickup: schedule_type === 'planned'
            ? moment(requestedAtFieldValue).add(margin || 0, 'minutes')
            : eta,
          duration: TimeUtils.secondsToTime(_.get(selectedEstimation, 'details.durations.total', 0), null, { hide_seconds: true })
        }
      } else if (!!order) {
        const { info: { dest_datetime, src_datetime }, requestedAt, quotation } = order
        return {
          price: BillingUtils.getEstimationDisplay(quotation, false, 1),
          distance: FormatUtils.distance(_.get(quotation, 'distance') || 0),
          duration: !!dest_datetime && TimeUtils.secondsToTime(moment(dest_datetime).diff(moment(src_datetime), 'seconds'), null, { hide_seconds: true }),
          estimatedDelay: TimeUtils.secondsToTime(moment(src_datetime).diff(moment(requestedAt), 'seconds'), null, { hide_seconds: true }),
          pickup: src_datetime,
        }
      } else return {}
    }
  }
}

export default Callbacks
