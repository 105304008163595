import React from 'react'
import { I18n } from '@front/volcanion'

import { Box, Typography, Grid, Button, Link, Tooltip, IconButton } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info';
import { TextComponent, TextField } from '@front/squirtle'
import { FileDownloadOutlined } from '@mui/icons-material';

const Title = ({ billing_status, billing_color, stripeLink }) =>
  <Box sx={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', mb: 2 }}>
    <Box sx={{ display: 'flex', gap: 1, alignItems: 'baseline' }}>
      <Typography variant='h5'>{I18n.t('billing.label', { count: 1 })}</Typography>
      {!!billing_status && (
        <Typography sx={{ color: billing_color, fontWeight: 600 }}>{I18n.t(`billing.status.${billing_status}`)}</Typography>
      )}
    </Box>
    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
      {!!stripeLink && (
        <Link href={stripeLink} target="_blank" rel="noopener">{I18n.t('quotation.more_detail')}</Link>
      )}
    </Box>
  </Box>

const Row1 = ({ load_type, updateBilledHandlingDuration, duration, formated_effective_handling_duration }) =>
  <Grid container item xs={12} spacing={3}>
    <Grid item xs={3}>
      <TextField
        name={'load_count'}
        label={I18n.t(`load.${load_type}.count`)}
        type='number'
        disabled
      />
    </Grid>
    <Grid item xs={3}>
      <TextComponent
        label={I18n.t('quotation.handling_duration')}
        value={formated_effective_handling_duration}
        afterUserChange={updateBilledHandlingDuration}
        disabled
      />
    </Grid>
    <Grid item xs={3}>
      <TextComponent
        label={I18n.t('quotation.trip_duration')}
        value={duration || '-'}
        disabled
      />
    </Grid>
  </Grid>

const Row2 = ({ handling, base, isDurationRideMode, duration_amount }) =>
  <Grid container item xs={12} spacing={3}>
    <Grid item xs={3}>
      <TextComponent
        label={(
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            {I18n.t('quotation.counter_amount')}
            <Tooltip title={I18n.t('quotation.counter_amount_nearing')}>
              <IconButton size="small" style={{ marginLeft: 4 }}>
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        value={`${base} ${I18n.t('fiat.eur.sign')}`}
        disabled
      />
    </Grid>
    <Grid item xs={3} hidden={!isDurationRideMode}>
      <TextComponent
        label={I18n.t('ride.duration.amount')}
        value={`${duration_amount} ${I18n.t('fiat.eur.sign')}`}
        disabled
      />
    </Grid>
    <Grid item xs={3}>
      <TextComponent
        label={I18n.t('wait.label', { count: 1 })}
        value={`${handling} ${I18n.t('fiat.eur.sign')}`}
        disabled
      />
    </Grid>
  </Grid>

const Row3 = ({ prepaid, fees, option_amount, driver_package_fee }) =>
  <Grid container item xs={12} spacing={3}>
    <Grid item xs={3}>
      <TextComponent
        label={I18n.t('quotation.fees')}
        value={`${fees} ${I18n.t('fiat.eur.sign')}`}
        disabled
      />
    </Grid>
    <Grid item xs={3}>
      <TextComponent
        label={I18n.t('quotation.prepaid')}
        value={`${prepaid} ${I18n.t('fiat.eur.sign')}`}
        disabled
      />
    </Grid>
    <Grid item xs={3}>
      <TextComponent
        label={I18n.t('options.label', { count: 2 })}
        value={`${option_amount} ${I18n.t('fiat.eur.sign')}`}
        disabled
      />
    </Grid>
    <Grid item xs={3}>
      <TextComponent
        label={I18n.t('quotation.duration_driver_package_fee')}
        value={`${driver_package_fee} ${I18n.t('fiat.eur.sign')}`}
        disabled
      />
    </Grid>
  </Grid>

const Row4 = ({ total, displayDownloadBillingButton, handleInvoiceDownload }) =>
  <Grid container item xs={12} spacing={3} direction="row-reverse" justifyContent={'space-between'} mt={2}>
    <Grid item xs={3}>
      <Typography sx={{ fontSize: 'h6.fontSize', fontWeight: 600, color: 'primary.main' }}>
        {`${I18n.t('quotation.total.label', { count: 1 })}: ${total}${I18n.t('fiat.eur.sign')}`}
      </Typography>
    </Grid>
    {!!displayDownloadBillingButton && (
      <Grid item xs >
        <Button
          sx={{ bgcolor: 'primary.main', color: 'white', "&:hover": { color: "primary.main", bgcolor: 'white' } }}
          size='small'
          variant='contained'
          onClick={handleInvoiceDownload}
        >
          <FileDownloadOutlined />
          <Typography sx={{ ml: 1, fontSize: 'inherit', fontWeight: 600 }}> {'Télécharger la facture'} </Typography>
        </Button>
      </Grid>
    )}
  </Grid>

const BillingSection = ({ title, row1, row2, row3, row4 }) =>
  <Box sx={{ p: 1, borderRadius: 1, boxShadow: 6 }}>
    <Title {...title} />
    <Grid container pt={1}>
      <Row1 {...row1} />
      <Row2 {...row2} />
      <Row3 {...row3} />
      <Row4 {...row4} />
    </Grid>
  </Box>

export default React.memo(BillingSection)
